var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("title", [_vm._v("currency_2@1x")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.92,11.53A4.1,4.1,0,0,1,2,10.64c0,.71.75,\n        1.29,1.87,1.57A1.47,1.47,0,0,1,4,11.79,1.34,1.34,0,0,1,3.92,11.53Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.92,10.19A4,4,0,0,1,2,9.31c.05.93.89,1.29,1.87,1.57A1.64,\n      1.64,0,0,1,4,10.45,1.14,1.14,0,0,1,3.92,10.19Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.86,8.85A4,4,0,0,1,2,8c0,.94,1.08,1.35,1.87,1.56a1.77,1.77,0,0,1,.26-.64Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M5.28,7.76A5,5,0,0,1,2,6.74c.22,1.17,1.6,1.45,2.66,1.61A3.93,3.93,0,0,\n        1,6,7.73C5.74,7.75,5.52,7.76,5.28,7.76Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.31,6.94a4.09,4.09,0,0,1-1.23.58,6.47,6.47,0,0,1,\n        1.08,0,1.06,1.06,0,0,0,.4-.75A2.43,2.43,0,0,1,8.31,6.94Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.37,5.66A5.75,5.75,0,0,1,2,5.5c.38,2.19,6.17,2.19,6.55,0Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.56,4.16C8.39,2,2.1,2,2,4.21,2.27,6.47,8.4,6.47,8.56,4.16Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.59,11.57a1.43,1.43,0,0,1-.08.22,1.63,1.63,0,0,1,.14.49c1.61.4,4.53-.37,\n        4.34-1.64C15.17,11.48,13.06,11.86,11.59,11.57Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M16,9.31a5.58,5.58,0,0,1-4.4.93,1.8,1.8,0,0,1-.08.21,1.78,1.78,0,0,\n      1,.14.5C13.25,11.22,15.85,10.91,16,9.31Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.72,6.34c-1.61,0-2.94.59-3.23,1.37.95.26,2,.85,2.16,\n        1.9,1.46.22,3.42.12,4.25-1.19C16.38,7.14,14.67,6.38,12.72,6.34Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M4.49,13.74C4.41,16,11.11,16,11,13.74,9.76,15.08,5.77,15.08,4.49,13.74Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11,12.41l0,0h0a5.74,5.74,0,0,1-6.5,0C4.64,14.72,10.89,14.72,11,12.41Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11,11.08l0,0a5.69,5.69,0,0,1-6.5,0C4.64,13.4,10.91,13.37,11,11.08Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11,9.45C10.16,8.09,8.2,8,6.78,8.19l-.51.1c-3,.88-1.75,2.92.77,3.14C8.26,11.62,11.41,11.22,11,9.45Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }